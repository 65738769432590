import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentHero {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".content-hero__heading")
    this.left = block.querySelectorAll(".content-hero__logo, .content-hero__eyebrow, .content-hero__heading, .content-hero__content, .content-hero__cta, .content-hero__statistic-wrapper, .content-hero__quote-wrapper")
    this.cards = block.querySelectorAll(".content-hero__animated-card")
    this.right = block.querySelector(".content-hero__right")
    this.image = block.querySelector(".content-hero__image")
    this.collageImages = block.querySelectorAll(".content-hero__collage-image")
    this.gsapInit()
  }

  gsapInit() {
    const mm = gsap.matchMedia()

    mm.add(
      {
        isSmall: "(max-width: 899px)",
        isLarge: "(min-width: 900px)",
      },
      (context) => {
        const {isSmall, isLarge} = context.conditions

        if (this.block.dataset.animations) {
          if (this.heading) {
            gsap.to(
              this.heading,
              {
                "--width": "104%",
                duration: 0.5,
                ease: "power2.inOut",
                scrollTrigger: {
                  trigger: this.heading,
                  start: () => "top 75%",
                  toggleActions: "play none none reverse",
                  refreshPriority: 100 - this.block.dataset.index,
                  invalidateOnRefresh: true,
                },
              },
              0
            )
            gsap.set(this.heading, {"--width": "0%"})
          }

          if (this.left) {
            this.left.forEach((el) => {
              gsap.to(el, {
                opacity: 1,
                y: 0,
                scrollTrigger: {
                  trigger: el,
                  start: () => "top 75%",
                  toggleActions: "play none none reverse",
                  refreshPriority: 100 - this.block.dataset.index,
                  invalidateOnRefresh: true,
                },
                ease: "power2.inOut",
                duration: 0.5,
              })
              gsap.set(el, {opacity: 0, y: 50})
            })
          }

          if (this.image) {
            gsap.to(this.image, {
              opacity: 1,
              duration: 0.5,
              delay: 0.25,
              scrollTrigger: {
                trigger: this.image,
                start: () => "top 75%",
                toggleActions: "play none none reverse",
                refreshPriority: 100 - this.block.dataset.index,
                invalidateOnRefresh: true,
              },
              ease: "power2.inOut",
            })
            gsap.set(this.image, {opacity: 0})
          }

          if (this.right) {
            gsap.to(this.right, {
              "--opacity": "1",
              duration: 0.5,
              delay: 0.5,
              scrollTrigger: {
                trigger: this.image,
                start: () => "top 75%",
                toggleActions: "play none none reverse",
                refreshPriority: 100 - this.block.dataset.index,
                invalidateOnRefresh: true,
              },
              ease: "power2.inOut",
            })
            gsap.set(this.right, {"--opacity": "0"})

            gsap.to(this.right, {
              "--rotation": "-32deg",
              duration: 0.25,
              delay: 0.5,
              scrollTrigger: {
                trigger: this.image,
                start: () => "top 75%",
                toggleActions: "play none none reverse",
                refreshPriority: 100 - this.block.dataset.index,
                invalidateOnRefresh: true,
              },
              ease: "power2.inOut",
            })
            gsap.set(this.right, {"--rotation": "0deg"})
          }
        }

        if (this.cards.length) {
          gsap.to(this.cards, {
            x: 0,
            y: 0,
            opacity: 1,
            stagger: 0.125,
            delay: 0.125,
            scrollTrigger: {
              trigger: this.cards,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
          })
          gsap.set(this.cards, {
            x: () => (isLarge ? "10%" : "50%"),
            y: () => (isLarge ? "-50%" : "-10%"),
            opacity: 0,
          })
        }

        if (this.collageImages.length) {
          gsap.to(this.collageImages, {
            opacity: 1,
            stagger: 0.125,
            delay: 0.125,
            scrollTrigger: {
              trigger: this.collageImages,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
          })
          gsap.set(this.collageImages, {opacity: 0})
        }
      }
    )
  }
}

export default ContentHero
