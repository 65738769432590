import * as THREE from "three"
import Globe from "globe.gl"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentImageRepeatTextBlocks {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".content-image-repeat-text-blocks__heading")
    this.animation = block.querySelector(".content-image-repeat-text-blocks__animation")
    this.svg = block.querySelector(".content-image-repeat-text-blocks__animation-wrapper svg")
    this.logos = block.querySelectorAll(".content-image-repeat-text-blocks__animation-wrapper svg > g")
    this.content = block.querySelectorAll(".content-image-repeat-text-blocks__content > *")
    this.gsapInit()

    if (this.animation) {
      this.threeInit()
      this.threeEvents()
    }

    this.events()
  }

  events() {
    window.addEventListener("resize", () => {
      if (this.globe) {
        this.globe.width(this.animation.offsetWidth)
        this.globe.height(this.animation.offsetHeight)
      }
    })
  }

  threeEvents() {
    const animation = this.block.querySelector(".content-image-repeat-text-blocks__animation")
    const canvas = animation.querySelector("canvas")

    canvas?.addEventListener("webglcontextlost", () => {
      this.globe.renderer().dispose()
      animation.classList.add("content-image-repeat-text-blocks__animation--hidden")
      console.log("Context lost, canvas hidden.")
    })
  }

  gsapInit() {
    if (this.block.dataset.animations) {
      if (this.content) {
        this.content.forEach((el) => {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: el,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
            duration: 0.5,
          })
          gsap.set(el, {opacity: 0, y: 50})
        })
      }

      if (this.heading) {
        gsap.to(
          this.heading,
          {
            "--width": "104%",
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: this.heading,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          },
          0
        )
        gsap.set(this.heading, {"--width": "0%"})
      }

      if (this.animation) {
        gsap.to(this.animation, {
          opacity: 1,
          scrollTrigger: {
            trigger: this.animation,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
            refreshPriority: 100 - this.block.dataset.index,
            invalidateOnRefresh: true,
          },
          ease: "power2.inOut",
          duration: 0.5,
        })
        gsap.set(this.animation, {opacity: 0})
      }

      if (this.logos) {
        gsap.to(this.logos, {
          scrollTrigger: {
            trigger: this.animation,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
            refreshPriority: 100 - this.block.dataset.index,
            invalidateOnRefresh: true,
          },
          scale: 1,
          opacity: 1,
          delay: 1,
          duration: 0.5,
          stagger: 0.125,
          ease: "power2.inOut",
        })
        gsap.set(this.logos, {opacity: 0, scale: 0, transformOrigin: "center"})
      }
    }
  }

  threeInit() {
    this.globe = Globe()
    this.clock = new THREE.Clock()

    const arcsData = [...Array(10).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 360,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 360,
      endLng: (Math.random() - 0.5) * 360,
      color: ["#76cdc4", "#0375bf"],
    }))

    this.globe(this.animation)
      .globeImageUrl("/wp-content/themes/designbypelling/img/earth-map.jpg")
      .width(this.animation.offsetWidth)
      .height(this.animation.offsetHeight)
      .backgroundColor("rgba(255, 255, 255, 0)")
      .showAtmosphere(false)
      .arcsData(arcsData)
      .arcColor("color")
      .arcDashLength(() => Math.random() + 0.5)
      .arcDashGap(() => Math.random() + 0.5)
      .arcDashAnimateTime(() => Math.random() * 4000 + 500)

    this.globe.controls().enabled = false
    this.globe.controls().autoRotate = true
    this.globe.controls().autoRotateSpeed = 0.5
    this.globe.renderer().powerPreference = "low-power"

    const directionalLight = this.globe.lights().find((light) => light.type === "DirectionalLight")
    const ambientLight = this.globe.lights().find((light) => light.type === "AmbientLight")
    directionalLight.position.set(0, 0, 0)
    ambientLight.intensity = 6
  }
}

export default ContentImageRepeatTextBlocks
