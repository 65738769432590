import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class CollageHero {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".collage-hero__heading")
    this.animationElements = block.querySelectorAll(".collage-hero__eyebrow, .collage-hero__heading, .collage-hero__content, .collage-hero__cta")
    this.imagesContainer = block.querySelector(".collage-hero__images")
    this.images = block.querySelectorAll(".collage-hero__image")
    this.gsapInit()
  }

  gsapInit() {
    if (this.block.dataset.animations) {
      if (this.heading) {
        gsap.to(
          this.heading,
          {
            "--width": "104%",
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: this.heading,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          },
          0
        )
        gsap.set(this.heading, {"--width": "0%"})
      }

      if (this.animationElements) {
        this.animationElements.forEach((el) => {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
              trigger: el,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
          })
          gsap.set(el, {opacity: 0, y: 50})
        })
      }

      if (this.images) {
        this.imagesTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: this.imagesContainer,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
          },
          ease: "power2.inOut",
        })

        this.imagesTimeline.from(
          this.images[2],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[1], this.images[3]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[0], this.images[4]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.5,
          },
          0
        )

        this.imagesTimeline.from(
          this.images[6],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.75,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[5], this.images[7]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 1,
          },
          0
        )
      }
    }
  }
}

export default CollageHero
