import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ImageText {
  constructor(block) {
    this.block = block
    this.content = block.querySelectorAll(".image-text__content-wrapper > *")
    this.image = block.querySelector(".image-text__image")
    this.imageBg = block.querySelector(".image-text__image-wrapper")
    this.gsapInit()
  }

  gsapInit() {
    if (this.block.dataset.animations) {
      if (this.content) {
        this.content.forEach((el) => {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: el,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
          })
          gsap.set(el, {opacity: 0, y: 50})
        })
      }

      if (this.image) {
        this.imageTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: this.image,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
            refreshPriority: 100 - this.block.dataset.index,
            invalidateOnRefresh: true,
          },
          ease: "power2.inOut",
        })

        this.imageTimeline.to(
          this.image,
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )

        if (this.imageBg) {
          this.imageTimeline.to(
            this.imageBg,
            {
              "--opacity": "1",
              duration: 0.5,
            },
            0
          )

          this.imageTimeline.to(
            this.imageBg,
            {
              "--rotation": "-32deg",
              duration: 0.25,
              delay: 0.5,
            },
            0
          )
        }

        gsap.set(this.image, {opacity: 0})
        gsap.set(this.imageBg, {"--opacity": "0", "--rotation": "0deg"})
      }
    }
  }
}

export default ImageText
