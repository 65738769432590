import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentColumnsIcons {
  constructor(block) {
    this.block = block
    this.cardsWrapper = block.querySelector(".content-columns-icons__wrapper")
    this.cards = block.querySelectorAll(".content-columns-icons__card")
    this.card1 = block.querySelector(".content-columns-icons__card--1")
    this.card2 = block.querySelector(".content-columns-icons__card--2")
    this.heading = block.querySelector(".content-columns-icons__heading")
    this.animationElements = block.querySelectorAll(".content-columns-icons__eyebrow, .content-columns-icons__heading, .content-columns-icons__content, .content-columns-icons__cta, .content-columns-icons__card")

    this.gsapInit()
    this.events()
  }

  events() {
    if (this.block.classList.contains("content-columns-icons--expanding")) {
      this.cards.forEach((card) => {
        card.addEventListener("mouseenter", () => this.activateCard(card))
      })
    }
  }

  activateCard(card) {
    this.cards.forEach((el) => {
      el.classList.toggle("content-columns-icons__card--active", el == card)
    })
  }

  gsapInit() {
    const mm = gsap.matchMedia()
    if (this.block.dataset.animations) {
      if (this.heading) {
        gsap.to(
          this.heading,
          {
            "--width": "104%",
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: this.heading,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          },
          0
        )
        gsap.set(this.heading, {"--width": "0%"})
      }

      if (this.animationElements) {
        this.animationElements.forEach((el) => {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: el,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          })
          gsap.set(el, {opacity: 0, y: 50})
        })
      }
    }

    if (this.block.classList.contains("content-columns-icons--parallax")) {
      this.timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.block,
          invalidateOnRefresh: true,
          start: () => "top 75%",
          toggleActions: "play none none reverse",
        },
        ease: "power2.inOut",
      })

      mm.add("(min-width: 992px)", () => {
        this.cards.forEach((card, index) => {
          this.timeline.fromTo(
            card,
            {
              x: () => `${(this.cards.length - index - 1) * card.offsetWidth + (this.cards.length - index - 1) * 20}px`,
            },
            {
              x: () => 0,
              duration: 0.5,
              // delay: 0.5,
              ease: "power2.in",
            },
            0
          )
        })
      })
    }
  }
}

export default ContentColumnsIcons
