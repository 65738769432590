import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentColumnsStatistics {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".content-columns-statistics__heading")
    this.headingSpan = block.querySelector(".content-columns-statistics__heading span")
    this.stats = block.querySelectorAll(".content-columns-statistics__stat")
    this.gsapInit()
  }

  gsapInit() {
    if (this.block.dataset.animations) {
      if (this.heading) {
        gsap.to(
          this.heading,
          {
            opacity: 1,
            y: 0,
            "--width": "104%",
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: this.heading,
              start: () => {
                const blockTop = this.block.getBoundingClientRect().top
                if (blockTop < window.innerHeight) {
                  return "top 100%"
                } else {
                  return "top 75%"
                }
              },
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          },
          0
        )
        gsap.set(this.heading, {opacity: 0, y: 50, "--width": "0%"})
      }

      if (this.stats) {
        this.stats.forEach((stat) => {
          gsap.fromTo(
            stat,
            {
              opacity: 0,
              y: 50,
            },
            {
              scrollTrigger: {
                trigger: stat,
                start: () => {
                  const blockTop = this.block.getBoundingClientRect().top
                  if (blockTop < window.innerHeight) {
                    return "top 100%"
                  } else {
                    return "top 75%"
                  }
                },
                toggleActions: "play none none reverse",
                refreshPriority: 100 - this.block.dataset.index,
                invalidateOnRefresh: true,
              },
              opacity: 1,
              y: 0,
              duration: 0.5,
              delay: 0.25,
              ease: "power2.inOut",
            }
          )
          gsap.set(stat, {opacity: 0, y: 50})
        })
      }
    }
  }
}

export default ContentColumnsStatistics
