import gsap from "gsap"

class CtaBar {
  constructor(block) {
    this.block = block
    this.close = block.querySelector(".cta-bar__close")
    this.events()
    this.setHeight()
  }

  events() {
    this.close?.addEventListener("click", () => this.handleCloseCta())
  }

  handleCloseCta() {
    this.block.classList.add("cta-bar--closing")
    gsap.to("body", {
      "--cta-bar-height": "0px",
      duration: 0.25,
      ease: "linear",
    })

    setTimeout(() => {
      this.block.remove()
    }, 250)
  }

  setHeight() {
    document.querySelector("body").style.setProperty("--cta-bar-height", `${this.block.offsetHeight}px`)
  }
}

export default CtaBar
