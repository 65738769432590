import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentTextOnly {
  constructor(block) {
    this.block = block
    this.content = block.querySelectorAll(".col > *")
    this.heading = block.querySelector(".content-text-only__heading")
    this.gsapInit()
  }

  gsapInit() {
    if (this.block.dataset.animations) {
      if (this.heading) {
        gsap.to(
          this.heading,
          {
            opacity: 1,
            y: 0,
            "--width": "104%",
            duration: 0.5,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: this.heading,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
          },
          0
        )
        gsap.set(this.heading, {opacity: 0, y: 50, "--width": "0%"})
      }

      if (this.content) {
        this.content.forEach((el) => {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
            scrollTrigger: {
              trigger: el,
              start: () => "top 75%",
              toggleActions: "play none none reverse",
              refreshPriority: 100 - this.block.dataset.index,
              invalidateOnRefresh: true,
            },
            ease: "power2.inOut",
          })
          gsap.set(el, {opacity: 0, y: 50})
        })
      }
    }
  }
}

export default ContentTextOnly
